import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { MsgService } from './msg.service';

import { Carlos } from './carlos.service'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public state: string = 'not_logged_in'
  // loading | not_logged_in | logged_in

  public user: any;
  public branches: any[];
  public branchUsers: string[];
  public isAdmin: boolean;
  public isAuthComplete: boolean = false;

  isMe(email) {
    return email == this.user.email
  }
  didLogin(user) {
    this.state = 'logged_in'
    this.user = user;
    this.msg.alert('Logged in as ' + this.user.email);
    Carlos.instance.Log.output('info',`User already logged in as ${this.user.email}`)
    console.log('User is ' + this.user.email);
    if ((this.dataService.config.branches.length > 0) && (this.dataService.config.admins.length > 0)) {
      this.checkUserPerms();
    } else {
      this.msg.sub('config-branches', () => {
        this.checkUserPerms();
      });
      this.msg.sub('config-admins', () => {
        this.checkUserPerms();
      });
      this.msg.pub('logged-in')
    }
  }
  didLogout() {
    this.user = null;
    this.state = 'not_logged_in'
    this.msg.alert('Logged out.');
    console.log('Logged out.');
  }
  didGetKicked() {
    this.didLogout()
  }
  checkForLogin() {
    const token = localStorage.getItem('token')
    if (token) {
      this.dataService.gdbCall('/whoami','GET').then(res => {
        console.log(res)
        this.didLogin({ email: res._id })
      })
    }
  }

  constructor(private dataService: DataService, private msg: MsgService) {
    this.branches = [];
    this.branchUsers = [];

    msg.sub('got-kicked', () => { this.didGetKicked() })

    this.checkForLogin()
  }

  async ensureAuthComplete() {
    if (this.isAuthComplete) {
      return
    } else {
      await this.msg.await('auth-complete');
    }
  }

  checkUserPerms() {
    if ((this.dataService.config.branches.length == 0) || (this.dataService.config.admins.length == 0)) {
      console.log('Config not yet fully loaded, aborting user permissions check.')
      return;
    }
    console.log('Config complete, performing user permissions check.');
    this.branches = [];
    this.branchUsers = [];
    if (this.dataService.config.admins.find(a => { return a == this.user.email })) {
      // is admin
      this.isAdmin = true;
      console.log('User is admin.');

      this.branches = this.dataService.config.branches;
      this.branchUsers.push(this.user.email);
    } else {
      this.isAdmin = false;
      this.dataService.config.branches.forEach(b => {
        if (b['users']) {
          let us = b.users as string[];
          if (us.includes(this.user.email)) {
            this.branches.push(b);
          }
        }
      });
      console.log('Authorized branches: ' + this.branches.map(b => { return b['name'] }).join(', '));

      this.branchUsers.push(this.user.email);
      this.branches.forEach(b => {
        if (b['users']) {
          let us = b.users as string[];
          us.forEach(u => {
            if ((u != this.user.email) && (!this.branchUsers.includes(u))) {
              this.branchUsers.push(u);
            }
          })
        }
        this.branchUsers.push()
      })
      console.log(this.branches);
      console.log('Other users: ' + this.branchUsers.join(', '));
    }
    this.isAuthComplete = true;
    this.msg.pub('auth-complete');
  }

  async changePassword(curPw, newPw) {
    let result = await this.dataService.gdbCall('/change-password', 'POST', {
      'currentPassword': curPw,
      'newPassword': newPw
    })
    if (result.httpStatus == 200) {
      this.msg.alert('Successfully updated your password.')
      return 0
    } else {
      this.msg.alert('Error updating password, please try again: ' + result.message)
      return -1
    }
  }

  login(email, password): Promise<any> {
    return this.dataService.gdbCall('/login','POST',{
      'e': email,
      'p': password
    }).then(res => {
        console.log('Logged in.');
        Carlos.instance.Log.output('info',`User ${email} logged in.`)
        localStorage.setItem('token', res.token)
        this.didLogin({
          email: res.user._id
        })
        return res
      }).catch(err => {
        return Promise.reject(err);
      });
  }
  logout() {
    // check if we are already logged out first
    if (this.state == 'not_logged_in') return;
    localStorage.removeItem('token')
    this.dataService.gdbCall('/logout','GET')
    this.didLogout()
  }
}
