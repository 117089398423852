import { Injectable } from '@angular/core';
import { MsgService } from './msg.service';

import { AuthService } from './auth.service';

interface RequestInitWithCredentials extends RequestInit {
  credentials?: 'include' | 'same-origin' | 'omit';
}

@Injectable({
  providedIn: 'root'
})

export class DataService {

  private gdbServer = window.location.hostname == 'localhost' ? 'http://127.0.0.1:5001' : 'https://gdb.exlibris.ph'

  constructor(private msg: MsgService) {
    msg.sub('logged-in', () => { this.getConfig() })
  }

  async gdbCall(url, method = 'GET', payload = {}, handler: (data) => void = null) {
    try {
      const token = localStorage.getItem('token')
      const args: RequestInitWithCredentials = method == 'POST' ? {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload)
      } : {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      //console.log(args)
      const response = await fetch(`${this.gdbServer}${url}`, args)
      if (!response.ok) {
        const data = await response.json()
        if (data.message == 'Token expired') {
          console.warn('Token expired. Please log in again.')
          this.msg.pub('got-kicked')
          return
        }
        throw new Error(`Error ${response.status} - ${data.message}`)
      }
      const data = await response.json()
      if (null != handler) handler(data)
      data.httpStatus = response.status
      return data
    } catch (error) {
      throw error
    }
  }

  public inFlight: boolean = false

  config = {
    branches: [],
    products: [],
    admins: [],
    checkups: [
      {
        interval: 'week',
        units: 2,
        label: 'Checkup'
      },
      {
        interval: 'month',
        units: 6,
        label: 'Checkup'
      },
      {
        interval: 'year',
        units: 3,
        label: 'Checkup'
      },
      {
        interval: 'year',
        units: 5,
        label: 'Checkup'
      },
    ],
    updateSubtypes: [],
  };

  setPref(key: string, val: string) {
    localStorage.setItem(key, val);
  }
  getPref(key: string) {
    let result = localStorage.getItem(key);
    console.log(`${key} -> ${result}`);
    return result;
  }

  setInflight(val: boolean) {
    setTimeout(() => { this.inFlight = val }, 0)
  }

  getUsers() {
    return this.gdbCall('/get-users')
  }
  setUserDisabled(uid: string, disabled: boolean) {
    return this.gdbCall('/set-user-disabled', 'POST', {
      'uid': uid,
      'disabled': disabled ? 1 : 0
    })
  }

  addUser(email: string) {
    return this.gdbCall('/add-user', 'POST', {
      'email': email,
    })
  }

  setAdmin(email: string, makeAdmin: boolean) {
    return this.gdbCall('/set-admin', 'POST', {
      'email': email,
      'admin': makeAdmin ? 1 : 0
    })
  }

  changePassword(curPw, newPw) {
    return this.gdbCall('/change-password', 'POST', {
      'currentPassword': curPw,
      'newPassword': newPw,
    })
  }

  resetUserPassword(email: string) {
    return this.gdbCall('/reset-password', 'POST', {
      'uid': email,
    })
  }

  async collisionCheck(args) {
    return await this.gdbCall('/collision-check', 'POST', args)
  }

  getConfig() {
    console.log('Loading config.');
    this.gdbCall('/admins', 'GET', {}, (data: any) => {
      this.config.admins = data
      console.log(this.config.admins)
      console.log('Admins loaded.');
      this.msg.pub('config-admins');
    })
    this.gdbCall('/branches', 'GET', {}, (data: any) => {
      this.config.branches = data
      this.config.branches.forEach(function (b) {
        b.id = b._id
      })
      console.log('Branches loaded.');
      this.msg.pub('config-branches');
    })
    this.gdbCall('/products', 'GET', {}, (data: any) => {
      this.config.products = data
      console.log('Products loaded.');
      this.msg.pub('config-products');
    })
    this.gdbCall('/update_subtypes', 'GET', {}, (data: any) => {
      this.config.updateSubtypes = data
      console.log('Update subtypes loaded.');
      this.msg.pub('config-update-subtypes');
    })
  }

  async saveProducts(products) {
    this.setInflight(true)
    this.config.products = products
    await this.gdbCall('/products','POST',this.config.products)
    this.setInflight(false)
  }

  async saveUpdateSubtypes(subtypes) {
    this.setInflight(true)
    this.config.updateSubtypes = subtypes
    await this.gdbCall('/update_subtypes','POST',this.config.updateSubtypes)
    this.setInflight(false)
  }

  getProductByCode(code) {
    return this.config.products.find(e => { return code == e.code });
  }
  getBranchById(id) {
    return this.config.branches.find(e => { return id == e.id });
  }

  declass(data: any) {
    let d = JSON.parse(JSON.stringify(data));
    console.log(d);
    return d;
  }

  async updateBranchUsers(branchId: string, users: string[]) {
    await this.gdbCall(`/branch_users/${branchId}`,'POST',{'users':users})
    console.log('Successfully updated branch users.');
    return 0
  }

  async getCustomersWithBirthday(branchCode: string, month: number, day: number) {
    let customers = await this.gdbCall(`/customers/birthday/${month}/${day}/${branchCode}`)
    return customers
  }

  async getCustomersWithCheckup(branchCode: string, date: Date) {
    let d = date.toISOString();
    let customers = await this.gdbCall(`/customers/checkup/${d}/${branchCode}`)
    return customers
  }

  async getCustomers(branchCodes: string[] = null) {
    // branchCodes is an array
    if ((null == branchCodes) || (branchCodes.length > 10)) {
      var customers = await this.gdbCall('/customers')
      return customers
    }
    // base customers directly owned by branch
    let codes = branchCodes.join(',')
    var customers = await this.gdbCall(`/customers/${codes}`)
    return customers
    // shared?
  }

  async getCustomer(id: string): Promise<Customer> {
    if (id) {
      let customer: Customer = new Customer();
      let d = await this.gdbCall(`/customer/${id}`)
      customer.enclass(d)
      customer.id = id
      return customer
    }
    return null;
  }

  addCheckupsToCustomer(customer: Customer, reckoningDate: Date) {
    this.config.checkups.forEach(schedule => {
      let checkupDate = this.dateAdd(reckoningDate, schedule.interval, schedule.units);
      console.log("Adding checkup for " + checkupDate);
      customer.checkupDates.push(checkupDate);
    });
  }

  async saveCustomer(customer: Customer) {
    this.inFlight = true
    if (!customer.isValid()) {
      console.log('Customer is not valid.');
      return;
    }
    if (!customer.id) {
      let createNote = new Update();
      createNote.text = 'Created';
      createNote.userId = 1;
      customer.updates.push(createNote);
    }
    let data = customer.declass();
    if (customer.id) {
      // pre existing
      await this.gdbCall(`/customer/${customer.id}`,'POST',data)
      this.inFlight = false
      return customer
    } else {
      let res = await this.gdbCall(`/customer`,'POST',data)
      console.log(res._id)
      customer.id = res._id
      this.inFlight = false
      return customer
    }
  }

  // https://stackoverflow.com/questions/1197928/how-to-add-30-minutes-to-a-javascript-date-object

  /**
   * Adds time to a date. Modelled after MySQL DATE_ADD function.
   * Example: dateAdd(new Date(), 'minute', 30)  //returns 30 minutes from now.
   * https://stackoverflow.com/a/1214753/18511
   * 
   * @param date  Date to start with
   * @param interval  One of: year, quarter, month, week, day, hour, minute, second
   * @param units  Number of units of the given interval to add.
   */
  dateAdd(date, interval, units) {
    if (!(date instanceof Date))
      return undefined;
    var ret = new Date(date); //don't change original date
    var checkRollover = function () { if (ret.getDate() != date.getDate()) ret.setDate(0); };
    switch (String(interval).toLowerCase()) {
      case 'year': ret.setFullYear(ret.getFullYear() + units); checkRollover(); break;
      case 'quarter': ret.setMonth(ret.getMonth() + 3 * units); checkRollover(); break;
      case 'month': ret.setMonth(ret.getMonth() + units); checkRollover(); break;
      case 'week': ret.setDate(ret.getDate() + 7 * units); break;
      case 'day': ret.setDate(ret.getDate() + units); break;
      case 'hour': ret.setTime(ret.getTime() + units * 3600000); break;
      case 'minute': ret.setTime(ret.getTime() + units * 60000); break;
      case 'second': ret.setTime(ret.getTime() + units * 1000); break;
      default: ret = undefined; break;
    }
    return ret;
  }

}

export class Customer {
  id: string;
  branchCode: string;
  firstName: string;
  lastName: string;
  birthdate: Date;
  gender: string;
  address: string;
  city: string;
  mobileNumber: string;
  email: string;

  isOutOfCountry: boolean;
  customerGroup: string;

  scId: string;
  pwdId: string;

  optin: boolean;

  referringDoctor: string;
  concerns: string;
  reasons: string;
  channel: string;
  otherChannel: string;

  age: number;

  updates: Update[];
  checkupDates: Date[];
  shares: Share[];

  isBorrowed: boolean;

  emergencyContactName: string;
  emergencyContactNumber: string;
  emergencyContactEmail: string;

  emergencyContactRelationship: string;
  emergencyContactRelationshipOther: string;
  emergencyContactChannel: string;
  emergencyContactChannelOther: string;

  registrationDate: Date;

  constructor() {
    this.birthdate = new Date();
    this.updates = [];
    this.checkupDates = [];
    this.shares = [];
    this.lastName = '';
    this.firstName = '';
    this.mobileNumber = '';

    this.customerGroup = '';

    this.registrationDate = new Date();
  }

  myAccess(dataService: DataService, authService: AuthService): string {
    // 'own' or 'borrowed' or 'deny'
    let r = 'deny';
    // check own branches
    let hasOwnAccess = false;
    authService.branches.forEach(b => {
      if (b.id == this.branchCode) { hasOwnAccess = true }
    })
    if (hasOwnAccess) {
      r = 'own';
    } else {
      // check for shares
      this.shares.forEach(s => {
        // check if this branch is accessible
        if (authService.branches.find(b => {
          return (b.id == s.branchCode);
        })) {
          // yes, can access this branch
          if (s.status() == 'active') {
            r = 'borrowed'
          }
        }
      })
    }
    return r;
  }

  enclass(d: any) {
    console.log(d);
    this.branchCode = d['branchCode'];
    this.lastName = d['lastName'];
    this.firstName = d['firstName'];
    this.birthdate = new Date(d['birthdate']);
    this.gender = d['gender'];
    this.address = d['address'];
    this.city = d['city'];
    this.mobileNumber = d['mobileNumber'];
    this.email = d['email'];

    this.isOutOfCountry = !!d['isOutOfCountry'];
    this.customerGroup = d['customerGroup'];

    this.scId = d['scId'];
    this.pwdId = d['pwdId'];

    this.optin = d['optin'];

    this.referringDoctor = d['referringDoctor'];
    this.concerns = d['concerns'];
    this.reasons = d['reasons'];
    this.channel = d['channel'];
    this.otherChannel = d['otherChannel'];

    this.emergencyContactName = d['emergencyContactName'] ? d['emergencyContactName'] : ''
    this.emergencyContactNumber = d['emergencyContactNumber'] ? d['emergencyContactNumber'] : ''
    this.emergencyContactEmail = d['emergencyContactEmail'] ? d['emergencyContactEmail'] : ''

    this.emergencyContactRelationship = d['emergencyContactRelationship'] ? d['emergencyContactRelationship'] : ''
    this.emergencyContactRelationshipOther = d['emergencyContactRelationshipOther'] ? d['emergencyContactRelationshipOther'] : ''
    this.emergencyContactChannel = d['emergencyContactChannel'] ? d['emergencyContactChannel'] : ''
    this.emergencyContactChannelOther = d['emergencyContactChannelOther'] ? d['emergencyContactChannelOther'] : ''

    this.updates = [];
    d['updates'].forEach(u => {
      this.updates.push(Update.fromRaw(u));
    });

    this.checkupDates = [];
    if (d['checkupDates']) {
      d['checkupDates'].forEach(c => {
        this.checkupDates.push(new Date(c));
      });
      this.checkupDates.sort((a, b) => {
        return a.valueOf() - b.valueOf();
      })
    }

    this.shares = [];
    if (d['shares']) {
      d['shares'].forEach(s => {
        let share = new Share();
        share.enclass(s);
        this.shares.push(share);
      });
    }

    this.registrationDate = new Date(d['registrationDate']);
  }
  declass() {
    let upd = this.updates.map(u => {
      return u.declass();
    });

    let cds = this.checkupDates.map(c => {
      return JSON.parse(JSON.stringify(c));
    });
    cds.sort((a, b) => {
      return a.valueOf() - b.valueOf();
    })

    let shares = this.shares.map(s => {
      return s.declass();
    })

    let d = JSON.parse(JSON.stringify(this));
    d.updates = upd;
    d.checkupDates = cds;
    d.shares = shares;

    delete d.isBorrowed;

    // meta
    let meta = {
      birth_month: this.birthdate.getUTCMonth(),
      birth_day: this.birthdate.getUTCDate(),
      last_name: this.lastName.toLowerCase(),
      first_name: this.firstName.toLowerCase(),
    }
    d.meta = meta;

    return d;
  }

  isValid(): boolean {
    let valid = true
      && (!!this.lastName)
      && (!!this.firstName);
    return valid;
  }

  prep() {
    this.calcAge();
    this.sortUpdates();
  }
  sortUpdates() {
    this.updates.sort((a, b) => {
      return (a.ts < b.ts) ? 1 : -1;
    });
  }
  calcAge() {
    let today = new Date();
    let age = today.getFullYear() - this.birthdate.getFullYear();
    let m = today.getMonth() - this.birthdate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < this.birthdate.getDate())) {
      age--;
    }
    this.age = age;
  }
}

export class Share {
  ts: Date;
  expiry: Date;
  branchCode: string;
  branch: any;
  isActive: boolean;

  enclass(raw: any) {
    this.ts = new Date(raw.ts);
    this.branchCode = raw.branchCode;
    this.expiry = new Date(raw.expiry);
    this.isActive = raw.isActive as boolean;
  }

  declass() {
    let d = JSON.parse(JSON.stringify(this));
    delete d.branch;
    return d;
  }

  status() {
    let now = new Date();
    if (!this.isActive) {
      return 'revoked';
    } else {
      if (now < this.expiry) {
        return 'active';
      } else {
        return 'expired';
      }
    }
  }

}

export class Update {
  ts: Date;
  tsOverride: Date;
  userId: number;
  type: string;
  subtype: string;
  text: string;

  deleted: any;

  dispenser: string;

  purchaseData: any;
  resultsData: any;

  reasonForPurchase: string;

  _last: string;

  effectiveDate(): Date {
    // strip time and just return UTC date
    let d = new Date();

    d.setUTCFullYear(this.ts.getFullYear());
    d.setUTCMonth(this.ts.getMonth());
    d.setUTCDate(this.ts.getDate());

    d.setUTCHours(0);
    d.setUTCMinutes(0);
    d.setUTCSeconds(0);
    d.setUTCMilliseconds(0);

    return (this.tsOverride == null) ? d : this.tsOverride;
  }

  constructor() {
    this.text = "";
    this.ts = new Date();
    this.tsOverride = null;
    this.type = 'update';
    this.subtype = '';
    this.dispenser = '';

    this.deleted = false;

    this.purchaseData = {
      productCode: '',
      side: ''
    };

    this.resultsData = {
      type: Update.config.tests[0].type,
      results: {
      }
    }

    this.reasonForPurchase = '';

    this._last = JSON.stringify(this.declass());
  }

  static config = {
    tests: [
      {
        type: 'puretone_audiometry',
        blank: {
          degreeLeft: 'normal',
          degreeRight: 'normal',
          typeLeft: '',
          typeRight: '',
          remarks: '',

          withTable: false,
          table: {
            rac: ['','','','','','','','','',''],
            rbc: ['','','','','','','','','',''],
            lac: ['','','','','','','','','',''],
            lbc: ['','','','','','','','','',''],
          }
        }
      },
      {
        type: 'speech_audiometry',
        blank: {
          degreeLeft: 'normal',
          degreeRight: 'normal',
          isConformedLeft: "0",
          isConformedRight: "0",
          wordRecognitionLeft: "0",
          wordRecognitionRight: "0",
          remarks: '',
        }
      },
      {
        type: 'tympanometry',
        blank: {
          typeLeft: '',
          typeRight: '',
          remarks: '',
        }
      }
    ]
  }

  static fromRaw(d: any): Update {
    let update: Update = new Update();
    update.enclass(d);
    return update;
  }

  enclass(d: any) {
    this.text = d['text'];
    this.userId = d['userId'];
    this.ts = new Date(d['ts']);
    this.tsOverride = d['tsOverride'] == null ? null : new Date(d['tsOverride']);
    this.type = d['type'];
    this.subtype = d['subtype'];
    this.dispenser = d['dispenser'];

    if (this.type == 'results') {
      this.resultsData = d['resultsData'];
    }
    if (this.type == 'purchase') {
      this.purchaseData = d['purchaseData'];
    }

    this.deleted = d['deleted'];
    if (this.deleted) { 
      this.deleted.dt = new Date(this.deleted.dt);
    }

    this.reasonForPurchase = d['reasonForPurchase'];

    this._last = JSON.stringify(this.declass());
  }
  declass() {
    let d = JSON.parse(JSON.stringify(this));
    delete d._last;
    if (d.type != 'purchase') { delete d.purchaseData; }
    if (d.type != 'results') { delete d.resultsData; }
    return d;
  }

  isDirty(): boolean {
    return (this._last != JSON.stringify(this.declass()));
  }
}

export class User {
  id: number;
  username: string;
}

export class DatePicker {
  month: number
  day: number
  year: number

  date: Date
  private callback: (date: Date) => void

  options = {
    month: [],
    day: [],
    year: [],
  }
  makeRange(s: number, e: number): number[] {
    let a: number[] = [];
    for (let i = s; i <= e; i++) {
      a.push(i);
    }
    return a;
  }
  buildOptions(options?: any) {
    if (!options) {
      options = {
        yearMin: -100,
        yearMax: 0,
      }
    }
    this.options.day = this.makeRange(1, 31);
    this.options.month = [
      { value: 0, label: 'Jan' },
      { value: 1, label: 'Feb' },
      { value: 2, label: 'Mar' },
      { value: 3, label: 'Apr' },
      { value: 4, label: 'May' },
      { value: 5, label: 'Jun' },
      { value: 6, label: 'Jul' },
      { value: 7, label: 'Aug' },
      { value: 8, label: 'Sep' },
      { value: 9, label: 'Oct' },
      { value: 10, label: 'Nov' },
      { value: 11, label: 'Dec' },
    ];
    let now = new Date();
    this.options.year = this.makeRange(now.getFullYear() + options.yearMin, now.getFullYear() + options.yearMax)
    this.options.year.reverse();
  }

  setCallback(callback: (date: Date) => void) {
    this.callback = callback;
  }

  constructor(date?: Date, options?: any) {
    this.buildOptions(options);
    this.fromDate(date ? date : new Date());
  }
  fromDate(date: Date) {
    this.month = date.getUTCMonth();
    this.day = date.getUTCDate();
    this.year = date.getUTCFullYear();

    this.date = this.toDate();
  }
  toDate(): Date {
    let d = new Date();

    d.setUTCFullYear(this.year);
    d.setUTCMonth(this.month);
    d.setUTCDate(this.day);

    d.setUTCHours(0);
    d.setUTCMinutes(0);
    d.setUTCSeconds(0);
    d.setUTCMilliseconds(0);
    return d;
  }
  changed() {
    this.date = this.toDate();
    if (this.callback) {
      this.callback(this.date);
    }
  }
}
