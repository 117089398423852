import { Component, OnInit } from '@angular/core';
import { MsgService } from 'src/app/msg.service';
import { DataService } from 'src/app/data.service';

@Component({
  selector: 'app-subtypes',
  templateUrl: './subtypes.component.html',
  styleUrls: ['./subtypes.component.scss']
})
export class SubtypesComponent implements OnInit {

  subtypes = []
  newSubtype = '';

  constructor(private msgSvc: MsgService, private dataService: DataService) { }

  ngOnInit(): void {
    this.subtypes = this.dataService.config.updateSubtypes;
  }

  removeSubtype(i: number) {
    this.subtypes.splice(i, 1);
  }

  addSubtype() {
    this.subtypes.push(this.newSubtype);
    this.newSubtype = '';
  }

  saveSubtypes() {
    this.dataService.saveUpdateSubtypes(this.subtypes)
    .then(() => {
      this.msgSvc.alert('Update subtypes saved.')
    })
    .catch((e) => {
      this.msgSvc.alert(`Error: ${e}`,true)
    })
  }

}
