import { Component, OnInit } from '@angular/core';
import { DataService, DatePicker, Customer } from '../../data.service';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-checkups',
  templateUrl: './checkups.component.html',
  styleUrls: ['./checkups.component.scss']
})
export class CheckupsComponent implements OnInit {

  pickers = {
    date: new DatePicker(),
  }

  lists = []

  reportIsLoaded = false;

  constructor(private authService: AuthService, private dataService: DataService) { }

  ngOnInit() {
  }

  async run() {
    await this.authService.ensureAuthComplete();
    console.log(this.pickers.date.toDate());
    this.lists = [];
    this.authService.branches.forEach(b => {
      let list = {
        branch: b,
        customers: [],
      }
      this.lists.push(list)
      this.dataService.getCustomersWithCheckup(b.id, this.pickers.date.toDate()).then(res => {
        this.reportIsLoaded = true;
        list.customers = [];
        res.forEach(c => {
          let customer = new Customer()
          customer.enclass(c);
          customer.id = c._id;
          customer.calcAge();
          list.customers.push(customer);
        })
      });
    });
  }

}
