import { Component, OnInit, ɵbypassSanitizationTrustResourceUrl } from '@angular/core';
import { DatePicker, DataService, Customer, Update, Share } from '../data.service';
import { ActivatedRoute } from '@angular/router';
import { NgbDateAdapter, NgbDateStruct, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../auth.service';
import { MsgService } from '../msg.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class CustomerComponent implements OnInit {

  id: string;
  customer: Customer;
  customerBranch: any;
  newUpdate: Update;
  withDateOverride: boolean;

  checkups: any[] = [];

  config;
  mobilePrefixes = [
    '0813', '0817', '0904', '0905', '0906', '0907', '0908', '0909',
    '0910', '0911', '0912', '0913', '0914', '0915', '0916', '0917', '0918',
    '0919', '0920', '0921', '0922', '0923', '0924', '0925', '0926', '0927',
    '0931',
  ];

  audiometryTableFreqs = [
    '250Hz', '500Hz', '750Hz', '1KHz', '1.5KHz', '2KHz', '3KHz', '4KHz', '6KHz', '8KHz'
  ]

  pickers = {
    birthdate: new DatePicker(),
    updateDateOverride: new DatePicker(),
  }

  showDeleted = 0
  updateFilter = 'all'
  unlocked = []
  isLocked(i) {
    return !(this.unlocked.findIndex(n => { return n == i }) >= 0);
  }
  lock(i, l = true) {
    if (l && !(this.isLocked(i))) {
      let idx = this.unlocked.findIndex(n => { return n == i });
      this.unlocked.splice(idx, 1);
    }
    if (!l && (this.isLocked(i)))
      this.unlocked.push(i);
  }
  deleteUpdate(u) {
    u.deleted = {
      dt: new Date(),
      dispenser: this.authService.user.email
    }
  }
  restoreUpdate(u) {
    delete u.deleted;
  }

  tab: number;

  share: string;
  shareBranch: string;

  constructor(private route: ActivatedRoute, public dataService: DataService, public authService: AuthService, private msgService: MsgService) {
    this.tab = 0;
    this.pickers.birthdate.setCallback(async (date) => {
      this.customer.birthdate = date;
      this.customer.calcAge();
      this.collisionCheck();
    })
    this.pickers.updateDateOverride.setCallback((date) => {
      console.log("Override date changed, setting update tsOverride...")
      this.newUpdate.tsOverride = date;
    })
  }

  collisionCheckTracker = {
    timeout: 0,
    count: 0
  }
  collisions = []
  collisionCheck() {
    console.log('Collision check...');
    this.collisionCheckTracker.count++;
    this.collisionCheckTracker.timeout = window.setTimeout(() => {
      this.collisionCheckTracker.count--;
      if (this.collisionCheckTracker.count <= 0) {
        this.collisionCheckTracker.count = 0;
        this.dataService.collisionCheck({
          lastName: this.customer.lastName,
          firstName: this.customer.firstName,
          birthdate: this.customer.birthdate.toISOString()
        }).then((res) => {
          console.log(res);
          if (res) {
            this.collisions = [];
            res.forEach((c) => {
              if (c.id != this.customer.id) {
                c.branch = this.dataService.getBranchById(c.branchCode);
                this.collisions.push(c);
              }
            });
          }
        })
      }
    }, 100);
  }

  applyCustomer() {
    this.pickers.birthdate.fromDate(this.customer.birthdate);

    this.checkups = [];
    this.customer.checkupDates.forEach(d => {
      let c = {
        beingEdited: false,
        picker: new DatePicker(d, { yearMin: 0, yearMax: 5 }),
        date: d,
      };
      c.picker.setCallback((d) => {
        c.date = d;
      });
      this.checkups.push(c);
    });

    this.withDateOverride = false;
    this.customerBranch = this.dataService.getBranchById(this.customer.branchCode);
    this.customer.shares.forEach(share => {
      share.branch = this.dataService.getBranchById(share.branchCode);
    });
    this.shareBranch = '0';
  }

  changeShareBranch(value) {
    if (value == '0') {
      this.share = null;
    }
  }

  addCheckup() {
    let d = new Date();
    let c = {
      beingEdited: true,
      picker: new DatePicker(d, { yearMin: 0, yearMax: 5 }),
      date: d,
    };
    c.picker.setCallback((d) => {
      c.date = d;
    });
    this.checkups.unshift(c);
  }
  removeCheckup(c) {
    for (var i = 0; i < this.checkups.length; i++) {
      if (this.checkups[i] == c) {
        this.checkups.splice(i, 1);
        return;
      }
    }
  }

  isLoaded = false;
  accessType = 'deny';
  homeBranchName = '';

  async ngOnInit() {
    await this.authService.ensureAuthComplete();

    this.config = this.dataService.config;

    this.customer = new Customer();
    this.id = this.route.snapshot.params.id;
    if (this.id) {
      this.dataService.getCustomer(this.id).then(customer => {
        this.isLoaded = true;

        // access check
        this.accessType = customer.myAccess(this.dataService, this.authService);
        if (this.accessType == 'borrowed') {
          this.homeBranchName = this.dataService.getBranchById(customer.branchCode).name;
        }

        console.log(customer);
        this.customer = customer;
        this.customer.prep();
        this.applyCustomer();
      });
    } else {
      this.isLoaded = true;
      this.customer = new Customer();
      this.customer.branchCode = this.authService.branches[0].id;
      console.log(this.authService.branches);
      this.accessType = 'own';
      this.applyCustomer();
    }
    this.resetNewUpdate();
  }

  logNewUpdate() {
    console.log(this.newUpdate);
  }
  testTypeChanged() {
    console.log(this.newUpdate.resultsData.type);
    let testConfig = Update.config.tests.find(t => { return t.type == this.newUpdate.resultsData.type });
    this.newUpdate.resultsData.results = JSON.parse(JSON.stringify(testConfig.blank));
    console.log(testConfig.blank);
    console.log('After testTypeChanged:');
    console.log(this.newUpdate);
  }
  toggleDateOverride() {
    console.log("Date override toggle");
    if (this.withDateOverride) {
      //      this.pickers.updateDateOverride = new DatePicker();
      this.newUpdate.tsOverride = this.pickers.updateDateOverride.toDate();
    } else {
      this.newUpdate.tsOverride = null;
    }
  }

  resetNewUpdate() {
    this.newUpdate = new Update();
    this.newUpdate.tsOverride = null;
    this.pickers.updateDateOverride.setCallback((date) => {
      this.newUpdate.tsOverride = date;
    })
    this.testTypeChanged();
  }

  mandatoryFields = [
    {
      key: 'emergencyContactRelationship',
      label: 'AHC Buddy - Relationship'
    },
    {
      key: 'emergencyContactChannel',
      label: 'AHC Buddy - How did he/she hear...'
    }
  ]

  save() {
    let validSave = true;
    let validUpdate = false;

    if (this.newUpdate.isDirty()) {
      this.newUpdate.dispenser = this.authService.user.email;
      // validate update
      validUpdate = true;
      if (this.newUpdate.type == 'purchase') {
        if (this.newUpdate.purchaseData.productCode == '') {
          validUpdate = false;
          this.msgService.alert('Please choose a valid product.');
        }
      }
      if (!validUpdate) {
        this.msgService.alert('Invalid update.');
        validSave = false;
      }
    }

    console.log(this.id)
    if (!this.id) {
      // only check for new customers
      this.mandatoryFields.forEach(fn => {
        var t = this.customer[fn.key];
        var v = !!t && (t != '')
        console.log(`Mandatory field: ${fn.key} -> ${t}; valid = ${v}`)
        if (!v) {
          this.msgService.alert(`"${fn.label}" is a required field.`)
        }
        validSave = validSave && v
      })
    }

    if (this.customer.lastName.trim() == '') {
      this.msgService.alert('Last name is required.');
      validSave = false;
    }
    if (this.customer.firstName.trim() == '') {
      this.msgService.alert('First name is required.');
      validSave = false;
    }

    if (this.customer.mobileNumber != '') {
      // test mobile syntax
      /*
      if (/^\d{11}$/.test(this.customer.mobileNumber) == false) {
        validSave = false;
        this.msgService.alert('Mobile number must be 11 digits.');
      }
      */
      // test mobile prefix
      let found = false;
      this.mobilePrefixes.forEach(p => {
        if (this.customer.mobileNumber.indexOf(p) == 0) {
          found = true;
        }
      })
      found = true; // disable mobile prefix check, per Gians feedback
      if (!found) {
        validSave = false;
        this.msgService.alert('Mobile number does not have a valid prefix.');
      }
    }

    this.customer.birthdate = this.pickers.birthdate.toDate();

    this.customer.checkupDates = [];
    this.checkups.forEach(c => {
      this.customer.checkupDates.push(c.date);
    });

    console.log("Valid update: " + validUpdate);
    if (validUpdate) {
      this.customer.updates.push(this.newUpdate);
      if (this.newUpdate.type == 'purchase') {
        console.log("Adding checkups against reckoning date of " + this.newUpdate.effectiveDate());
        this.dataService.addCheckupsToCustomer(this.customer, this.newUpdate.effectiveDate());
      }

      // reset newUpdate
      this.resetNewUpdate();
    }

    // sharing
    console.log("Share: " + this.share + " " + this.shareBranch);
    if (this.share) {
      if (this.shareBranch == this.customerBranch.id) {
        this.msgService.alert('Customer cannot be shared to his/her own branch.');
        validSave = false;
      } else {
        // valid share
        if (validSave) {
          let share = new Share();
          share.ts = new Date();
          share.expiry = this.dataService.dateAdd(share.ts, 'hour', 24);
          share.branchCode = this.shareBranch;
          share.branch = this.dataService.getBranchById(share.branchCode);
          share.isActive = true;
          this.msgService.alert('Customer shared with branch ' + share.branch.name + '.');
          this.customer.shares.push(share);
          this.share = null;
        }
      }
    }

    if (validSave) {
      this.dataService.saveCustomer(this.customer)
        .then((r) => {
          this.customer.updates.sort((a, b) => {
            return (a.ts < b.ts) ? 1 : -1;
          });
          this.resetNewUpdate();
          this.msgService.alert('Saved.');
          this.applyCustomer();
        })
        .catch((e) => {
          this.msgService.alert(`Save failed: ${e}`);
        })
    } else {
      this.msgService.alert('Save failed.');
    }

  }

}

