import { Component, OnInit, ViewChild, ElementRef, ApplicationRef, ChangeDetectorRef, NgZone } from '@angular/core';
import { DataService } from './data.service';
import { AuthService } from './auth.service';
import { MsgService } from './msg.service';
import { Router } from '@angular/router';
import { timeout } from 'rxjs/operators';
import { Carlos } from './carlos.service';

class Alert {
  public msg: string
  public sticky: boolean

  constructor(msg, sticky = false) {
    this.msg = msg
    this.sticky = sticky
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'active-crm';

  loginForm = {
    email: '',
    password: '',
    error: ''
  }

  alerts: Alert[] = [];

  constructor(private router: Router, public dataService: DataService, public authService: AuthService, private msgService: MsgService, private zone: NgZone) {
    this.prepConfig();
    msgService.registerAlerter((msg: string, waitForConfirm: boolean) => {
      this.alert(msg, waitForConfirm);
      console.log('Alert: ' + msg);
    });
    console.log('Alerts ready.');
    console.log('v0.3');
  }

  alert(msg: string, waitForConfirm: boolean) {
    let newAlert = new Alert(msg, waitForConfirm)
    this.alerts.push(newAlert)
    if (!waitForConfirm) {
      setTimeout(() => {
        this.removeAlert(newAlert);
      }, 2000);
    }
  }
  removeAlert(alert) {
    this.alerts.splice(this.alerts.findIndex((v)=>(v == alert)), 1);
    this.zone.run(() => { });
  }

  prepConfig() {
    this.dataService.getConfig();
  }

  login() {
    this.authService.login(this.loginForm.email, this.loginForm.password)
      .then(res => {
        this.router.navigate(['']);
      })
      .catch(err => {
        this.loginForm.error = err.message;
      });
  }

  userSwitch(v) {
    console.log(v);
    if (v == '/') {
      this.authService.logout();
      this.loginForm = {
        email: '',
        password: '',
        error: ''
      }
    } else if (v != this.authService.user.email) {
      this.authService.logout();
      this.loginForm = {
        email: v,
        password: '',
        error: ''
      }
    }
  }
}
