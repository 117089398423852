import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MsgService {

  subs: any[];
  idx: number;

  constructor() {
    this.idx = 0;
    this.subs = [];
    this.alerter = null;
  }

  public sub(topic, callback: () => any): number {
    console.log(`Sub added to: ${topic}`);
    let newSub = {
      index: this.idx,
      topic: topic,
      callback: callback
    };
    this.subs.push(newSub);
    this.idx++;
    return newSub.index;
  }

  public unsub(index: number) {
    let i = this.subs.findIndex(s => { return s.index == index });
    this.subs.splice(i, 1);
  }

  public pub(topic) {
    console.log(`Pub sent on: ${topic}`);
    this.subs.forEach(s => {
      if (s.topic == topic) {
        s.callback();
      }
    })
  }

  public async await(topic) {
    return new Promise((res, rej) => {
      this.sub(topic, () => {
        res(null);
      })
    })
  }

  alerter: (msg: string, waitForConfirm: boolean) => void;

  public registerAlerter(callback: (msg: string, waitForConfirm: boolean) => void) {
    this.alerter = callback;
  }
  public alert(msg: string, waitForConfirm: boolean = false) {
    if (this.alerter) {
      this.alerter(msg, waitForConfirm);
    }
  }
}
