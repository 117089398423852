import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CustomerComponent } from './customer/customer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SettingsComponent } from './user/settings/settings.component';

import { ReportsComponent } from './reports/reports.component';
import { BirthdayComponent } from './reports/birthday/birthday.component';
import { CheckupsComponent } from './reports/checkups/checkups.component';

import { AdminComponent } from './admin/admin.component';

const routes: Routes = [
  { path: '', component: DashboardComponent },
  { path: 'user/settings', component: SettingsComponent },
  { path: 'customer', component: CustomerComponent },
  { path: 'customer/:id', component: CustomerComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'reports', component: ReportsComponent },
  { path: 'reports/birthday', component: BirthdayComponent },
  { path: 'reports/checkups', component: CheckupsComponent },
  { path: 'admin', component: AdminComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
