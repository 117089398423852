import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService, Customer, Share } from '../data.service';
import { AuthService } from '../auth.service';
import { saveAs } from 'file-saver';
import { MsgService } from '../msg.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  customers: any[] = [];
  sortedCustomers: any[] = [];
  filteredCustomers: any[] = [];
  searchKey: string = '';

  withSharedCustomers: boolean = false;
  isLoading = false;
  adminLoadedCustomers: boolean = false;

  constructor(private dataService: DataService, private authService: AuthService, private msg: MsgService) { }

  loadAllCustomers() {
    if (!window.confirm('Are you sure you want to do this? This will make loading slow. Be sure to only use this if needed, and to set it back when you are done.')) return;
    this.dataService.setPref('dashboard-with-shared', 'y');
    this.initializeCustomers();
  }

  unloadSharedCustomers() {
    this.dataService.setPref('dashboard-with-shared', 'n');
    this.initializeCustomers();
  }

  loadCustomersUsingPref() {
    if (this.withSharedCustomers) {
      this.loadCustomers(null);
    } else {
      let branches = this.authService.branches.map(b => b.id as string);
      console.log(branches);
      this.loadCustomers(branches);
    }
  }

  loadCustomers(branches: string[]) {
    let startTime = Date.now();
    this.customers = [];
    this.filteredCustomers = [];
    this.isLoading = true;
    this.msg.alert(`Loading customers...`);
    this.dataService.getCustomers(branches).then(res => {
      this.isLoading = false;
      this.customers = [];
      let loadTime = Date.now();
      res.forEach(c => {
        let data = c;
        let isBorrowed = false;
        let hasAccess = false;
        hasAccess = hasAccess || (this.authService.branches.find(b => {
          return (b.id == data['branchCode']);
        }));
        if (!hasAccess) {
          // check for share
          if (data['shares']) {
            let shares = data['shares'] as any[];
            shares.forEach(s => {
              let share = new Share();
              share.enclass(s);
              if (this.authService.branches.find(b => {
                return (b.id == share.branchCode);
              }) && (share.status() == 'active')) {
                hasAccess = true;
                isBorrowed = true;
              }
            })
          }
        }
        if (hasAccess) {
          let customer: any = c;
          customer.isBorrowed = isBorrowed;
          customer.id = c._id;
          customer.branch = this.dataService.config.branches.find(b => { return b.id == customer.branchCode });
          this.customers.push(customer);
        }
      })
      this.sortCustomers();
      this.filterCustomers();
      let processTime = Date.now();
      this.msg.alert(`${res.length} customers loaded in ${loadTime-startTime}ms and processed in ${processTime-loadTime}ms.`);
    });
  }

  initializeCustomers() {
    if (this.authService.isAdmin) {
      if (this.dataService.getPref('admin-load-customers') == 'y') {
        this.dataService.setPref('admin-load-customers', 'n');
        this.loadCustomers(null);
        this.adminLoadedCustomers = true;
      }
    } else {
      if (this.dataService.getPref('dashboard-with-shared') == 'y') {
        this.withSharedCustomers = true;
      }
      this.loadCustomersUsingPref();
    }
  }

  adminLoadCustomers() {
    if (!window.confirm('Are you sure you want to do this? This may take some time. Use this only if needed.')) return;
    this.dataService.setPref('admin-load-customers', 'y');
    this.initializeCustomers();
  }

  authCompleteSub: number = -1
  ngOnInit() {
    if (this.authCompleteSub < 0) {
      this.authCompleteSub = this.msg.sub('auth-complete', () => {
        this.initializeCustomers();
      })
    } else {
      console.log('Already subbed to auth-complete')
    }
    if (this.authService.isAuthComplete) {
      this.initializeCustomers();
    }
  }
  ngOnDestroy() {
    if (this.authCompleteSub >= 0) {
      this.msg.unsub(this.authCompleteSub)
      console.log('Unsubbed to auth-complete')
    }
    this.authCompleteSub = -1
  }

  compareName(a: string, b: string): number {
    let al = a.toLowerCase();
    let bl = b.toLowerCase();
    if (al == bl) return 0
    else return (al < bl) ? -1 : 1;
  }

  sortCustomers() {
    this.sortedCustomers = this.customers.sort((a, b) => {
      let lnComp = this.compareName(a.lastName, b.lastName);
      if (lnComp == 0) {
        return this.compareName(a.firstName, b.firstName);
      } else {
        return lnComp;
      }
    });
  }

  maxToShow = 12;
  exceededMax = false
  filterCustomers() {
    var searchKeys: string[]

    let searchKeyLC = this.searchKey.toLowerCase();
    this.filteredCustomers = [...this.sortedCustomers]
    if (searchKeyLC != '') {
      searchKeys = searchKeyLC.split(' ')
      searchKeys.forEach(key => {
        this.filteredCustomers = this.filteredCustomers.filter(c => {
          return ((c.firstName + ' ' + c.lastName).toLowerCase().includes(key));
        });
      })
    }

    this.exceededMax = this.filteredCustomers.length > this.maxToShow
    this.filteredCustomers = this.filteredCustomers.splice(0, this.maxToShow);
  }

  search() {
    this.filterCustomers();
  }

  downloadMain() {
    let bom = "\uFEFF";

    let outSchedules = "Customer ID,Branch,Date\r\n";
    let outPurchases = "Customer ID,Branch,Date,Product Code,Product Name,Side,Serial\r\n";

    let out = bom + "Customer ID,Branch,Last Name,First Name,DoB,Sex,Address,Phone,Email,Customer Group, SC ID, PWD ID, Emergency Contact Name, Emergency Contact Number, Emergency Contact Email,Heard about AHC,Referring Doctor,Other,Concerns,Reason for looking\r\n";
    this.customers.forEach(c => {
      out += this.makeSafeArr([
        c.id,
        c.branch.name,
        c.lastName,
        c.firstName,
        this.utcToString(c.birthdate),
        c.gender,
        c.address,
        c.mobileNumber,
        c.email,
        c.customerGroup,
        c.scId,
        c.pwdId,
        c.emergencyContactName,
        c.emergencyContactNumber,
        c.emergencyContactEmail,
        c.channel,
        c.referringDoctor,
        c.otherChannel,
        c.concerns,
        c.reasons
      ])
        .join(',') + "\r\n";

      c.checkupDates.forEach(u => {
        outSchedules += this.makeSafeArr([
          c.id,
          c.branch.name,
          this.utcToString(u)
        ])
          .join(',') + "\r\n";
      });

      c.updates.forEach(u => {
        if (!u.deleted && (u.type == 'purchase')) {
          if (u.purchaseData) {
            outPurchases += this.makeSafeArr([
              c.id,
              c.branch.name,
              this.utcToString(u.tsOverride ? u.tsOverride : u.ts),
              u.purchaseData.productCode,
              this.dataService.getProductByCode(u.purchaseData.productCode).name,
              u.purchaseData.side,
              u.purchaseData.serialNumber
            ]).join(',') + "\r\n";
          }
        }
      });
    });

    out = out + "\r\n" + outSchedules;
    out = out + "\r\n" + outPurchases;

    var blob = new Blob([out], { type: 'text/csv' });
    saveAs(blob, 'ahc-customers-and-checkups.csv');
  }

  downloadUpdates() {
    let bom = "\uFEFF";

    let outUpdates = "Customer ID,Branch,Date,Update Type,Update Subtype,Reason for Purchase\r\n";

    let out = bom + "Customer ID,Branch,Earliest Date,Last Name,First Name,DoB,Sex,Address,Phone,Email,Customer Group\r\n";

    var earliestDate;

    this.customers.forEach(c => {

      c.updates.sort((a,b) => {
        return (a.tsOverride ? a.tsOverride : a.ts) > (b.tsOverride ? b.tsOverride : b.ts) ? 1 : -1;
      });

      c.updates.forEach(u => {
        if (!u.deleted) {
          var typeLabel;
          switch (u.type) {
            case 'update':
              typeLabel = 'General update';
              break;
            case 'purchase':
              typeLabel = 'Purchase';
              break;
            case 'result':
              typeLabel = 'Test result';
              break;
            default:
              typeLabel = '';
          }
          outUpdates += this.makeSafeArr([
            c.id,
            c.branch.name,
            this.utcToString(u.tsOverride ? u.tsOverride : u.ts),
            typeLabel,
            u.subtype,
            u.reasonForPurchase
          ]).join(',') + "\r\n";
        }
      });

      earliestDate = c.updates.length > 0 ? this.utcToString(c.updates[0].tsOverride ? c.updates[0].tsOverride : c.updates[0].ts) : 'n/a';

      out += this.makeSafeArr([
        c.id,
        c.branch.name,
        earliestDate,
        c.lastName,
        c.firstName,
        this.utcToString(c.birthdate),
        c.gender,
        c.address,
        c.mobileNumber,
        c.email,
        c.customerGroup
      ])
        .join(',') + "\r\n";

    });

    out = out + "\r\n" + outUpdates;

    var blob = new Blob([out], { type: 'text/csv' });
    saveAs(blob, 'ahc-customers-and-updates.csv');
  }

  utcToString(u: string): string {
    return this.dateToString(new Date(u));
  }
  dateToString(d: Date): string {
    console.log(d);
    let month = (d.getUTCMonth() + 1).toString().padStart(2, '0');
    let day = d.getUTCDate().toString().padStart(2, '0');
    let year = d.getUTCFullYear();

    return `${month}/${day}/${year}`;
  }
  makeSafeArr(sa: string[]): string[] {
    return sa.map(s => {
      return this.makeSafe(s);
    });
  }
  makeSafe(s): string {
    return s ? '"' + s.replace(/\"/g, "\"\"") + '"' : '""';
  }

}
