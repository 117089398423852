import Axios from 'axios'

const config = {
    url: 'https://carlos.funnycowgames.dev',
    key: 'ahc123'
}

export class Carlos {

    static instance: Carlos

    static init() {
        Carlos.instance = new Carlos()
    }

    static formUrl(endpoint: string) {
        return config.url + (endpoint[0] != '/' ? '/' : '') + endpoint
    }

    _callApi = (method, url, data) => {
        Axios({
            method: method,
            url: url,
            data: {
                key: config.key,
                ...data
            }
        })
    }
    _post = (url, data) => {
        this._callApi('post', Carlos.formUrl(url), data)
    }

    Log = {
        output: (level, message) => {
            /*
            this._post('log', {
                ts: new Date(),
                level: level,
                message: message
            })
            */
        }
    }
}
Carlos.init()