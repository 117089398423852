import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { AuthService } from '../../auth.service'
import { DataService } from '../../data.service';
import { MsgService } from '../../msg.service'
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  curPw: string = ''
  newPw: string = ''
  newPw2: string = ''

  constructor(private router: Router, private dataService: DataService, private authSvc: AuthService, private msgSvc: MsgService) { }

  ngOnInit() {
  }

  updatePw() {
    if (this.newPw != this.newPw2) {
      this.msgSvc.alert('New passwords do not match, please retry.')
      return
    }
    if (String(this.newPw).length < 6) {
      this.msgSvc.alert('Passwords must be at least 6 characters long.')
      return
    }
    this.dataService.changePassword(this.curPw, this.newPw)
      .then(res => {
        console.log(res)
        if (res.httpStatus == 200) {
          this.msgSvc.alert('Please login again.')
          this.authSvc.logout()
          this.router.navigate(['/'])
        }
      })
      .catch(e => {
        let errorMsg = 'Error updating password.';

        if (e instanceof HttpErrorResponse) {
          if (e.error && typeof e.error === 'object') {
            // Assuming the error response is a JSON object
            const errorResponse = e.error;
            errorMsg = errorResponse.message || 'An error occurred';
            // You can access other fields as needed
            // console.log('Error details:', errorResponse.details);
          } else if (typeof e.error === 'string') {
            // If the error response is a string
            errorMsg = e.error;
          } else {
            // Fallback error message
            errorMsg = e.message || 'An error occurred';
          }
        } else {
          // Handle other types of errors (e.g., network errors)
          errorMsg = e.message || 'An error occurred';
        }
    
        console.error('Error:', e);
        this.msgSvc.alert(errorMsg);
      })
  }

}
