import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { DataService } from '../data.service';
import { Router } from '@angular/router';
import { MsgService } from '../msg.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  tab = 'users'

  branches = []

  products = []

  users = []
  newUser = {
    email: '',
    password: ''
  }

  constructor(private msgSvc: MsgService, private router: Router, private dataService: DataService, private authService: AuthService) {
    if (!this.authService.isAdmin) {
      router.navigate(['/']);
    }
  }

  ngOnInit() {
    // clone it into here
    this.branches = JSON.parse(JSON.stringify(this.dataService.config.branches))
    console.log(this.branches)

    this.getUsers()

    this.products = JSON.parse(JSON.stringify(this.dataService.config.products))
    this.productApplyOrder()
    console.log(this.products)
  }

  setTab(tab) {
    this.tab = tab;
  }

  getUsers() {
    this.dataService.getUsers()
      .then((res) => {
        this.users = res
        this.users.forEach(u => {
          u.isAdmin = this.dataService.config.admins.includes(u._id)
        })
        this.users.sort((a, b) => (a._id.localeCompare(b._id)))
      })
  }
  setAdmin(user, makeAdmin){
    this.dataService.setAdmin(user._id, makeAdmin)
      .then((res) => {
        if (res.result > 0) {
          user.isAdmin = makeAdmin
        }
      })
  }

  createUser() {
    this.dataService.addUser(this.newUser.email)
      .then((res) => {
        console.log(res)
        this.msgSvc.alert(`New user created: ${this.newUser.email}. Password sent via email.`, true)
        let createdUser = {
          _id: this.newUser.email,
          disabled: false,
        }
        this.users.push(createdUser)
        this.users.sort((a, b) => (a._id.localeCompare(b._id)))
        this.newUser = {
          email: '',
          password: ''
        }
      })
      .catch((error) => {
        this.msgSvc.alert(`Error adding user ${this.newUser.email}: ${error.message}.`, true)
      })
  }
  setUserDisabled(user, disabled) {
    this.dataService.setUserDisabled(user._id, disabled)
      .then((res) => {
        user.disabled = disabled
        this.msgSvc.alert(`User ${user._id} is now ${disabled ? 'disabled' : 'enabled'}`)
      })
  }
  resetUserPassword(user) {
    this.dataService.resetUserPassword(user._id)
      .then((res) => {
        this.msgSvc.alert(`Password for user ${user._id} has been reset. New password has been sent via email.`, true)
      })
  }

  removeUser(branch, user) {
    for (let i = 0; i < branch.users.length; i++) {
      if (branch.users[i] == user) {
        branch.users.splice(i, 1)
        return
      }
    }
  }
  addUser(branch, user) {
    if (!branch.users) branch.users = []
    branch.users.push(user)
    branch.newUser = ''
  }

  dedupeList(arr) {
    for (let i = 0; i < arr.length; i++) {
      for (let j = i + 1; j < arr.length; j++) {
        if (j != i) {
          if (arr[i] == arr[j]) {
            arr.splice(j, 1)
          }
        }
      }
    }
  }

  productApplyOrder() {
    for (var i = 0; i < this.products.length; i++) {
      this.products[i].order = i
    }
  }
  productMove(p, delta) {
    let i = this.products.findIndex((i) => (i == p))
    let e = this.products.splice(i, 1)
    this.products.splice(i + delta, 0, ...e)
    this.productApplyOrder()
  }
  productRemove(p) {
    let i = this.products.findIndex((i) => (i == p))
    this.products.splice(i, 1)
    this.productApplyOrder()
  }
  productInsertAfter(p) {
    let i = this.products.findIndex((i) => (i == p))
    this.products.splice(i + 1, 0, { code: '', name: '', order: 0, id: '' })
    this.productApplyOrder()
  }
  saveProducts() {
    let items = []
    this.products.forEach((e) => {
      let i = JSON.parse(JSON.stringify(e))
      if (i.id) delete i.id
      if (i.code != '' && i.name != '') items.push(i)
    })
    this.dataService.saveProducts(items)
      .then(() => {
        this.msgSvc.alert('Product list saved.')
      })
      .catch((e) => {
        this.msgSvc.alert(`Error: ${e}`, true)
      })
  }

  saveBranches() {
    console.log('Saving...')
    let dirtyCount = 0;
    // check for dirty branches
    this.branches.forEach(b => {
      this.dataService.config.branches.forEach(cb => {
        if (b.id == cb.id) {
          let isDirty = false
          if (!b.users) b.users = []
          if (!cb.users) cb.users = []
          this.dedupeList(b.users)
          this.dedupeList(cb.users)
          if (b.users.length != cb.users.length) {
            isDirty = true
          } else {
            b.users.sort()
            cb.users.sort()
            for (let i = 0; i < b.users.length; i++) {
              if (b.users[i] != cb.users[i]) {
                isDirty = true
              }
            }
          }
          if (isDirty) {
            dirtyCount++
            console.log('Branch ' + b.name + ' is dirty, saving...')
            this.dataService.updateBranchUsers(b.id, b.users).then(() => {
              this.msgSvc.alert('Saved updates to branch ' + b.name)
            })
              .catch(e => {
              })
          }
        }
      })
    })
    console.log('Dirty branches being saved: ' + dirtyCount)
  }

}
